
/*
 * @Descripttion:
 * @version:
 * @Author: wenwen
 * @Date: 2023-11-22 18:55:06
 * @LastEditors: wenwen
 * @LastEditTime: 2024-02-05 17:34:14
 */
import { post, get } from '@/utils/request'
const path = '/train'
const API = {
  // 获取课程分组
  getCourseTree: path + '/courseGrouping/courseGroupTree',
  //   新增课程分组
  addAndEditCourseGroupTree: path + '/courseGrouping/createOrUpdate',
  // 删除课程分组
  deleteCourseGroupTree: path + '/courseGrouping/remove',
  // 课程分组详情
  getCourseGroupDetail: path + '/courseGrouping/details',
  // 课程 分页
  courseFindByPageList: path + '/course/findByPage',
  // 新增课程
  addCourse: path + '/course/add',
  // 课程详情
  getCourseDetail: path + '/course/detail',
  // 课程删除
  deleteCourse: path + '/course/remove',
  // 课程详情取消发布
  publishStatus: path + '/course/updateStatus',
  // 课程基本信息编辑
  courseBaseInforEdit: path + '/course/edit',
  // 课程目录列表
  getCourseCatalogue: path + '/trCourseSourceFile/getSourceFile',
  // 课程目录修改
  courseCatalogueEdit: path + '/trCourseSourceFile/edit',
  // 课程目录删除
  courseCatalogueDetele: path + '/trCourseSourceFile/remove',
  // 课程目录新增
  courseCatalogueAdd: path + '/trCourseSourceFile/add',
  // 操作记录
  getOperationRecord: path + '/trOptLog/findByPage'
}
export const getCourseTreeApi = (data) => get(API.getCourseTree, data)
export const addAndEditCourseGroupTreeApi = (data) => post(API.addAndEditCourseGroupTree, data)
export const deleteCourseGroupTreeApi = (data) => post(API.deleteCourseGroupTree, data)
export const getCourseGroupDetailApi = (data) => get(API.getCourseGroupDetail, data)
export const courseFindByPageListApi = (data) => post(API.courseFindByPageList, data)
export const addCourseApi = (data) => post(API.addCourse, data)
export const courseDetailApi = (data) => get(API.getCourseDetail, data)
export const deleteCourseApi = (data) => post(API.deleteCourse, data)
export const publishStatusApi = (data) => post(API.publishStatus, data)
export const courseBaseInforEditApi = (data) => post(API.courseBaseInforEdit, data)
export const getCourseCatalogueApi = (data) => get(API.getCourseCatalogue, data)
export const courseCatalogueEditApi = (data) => post(API.courseCatalogueEdit, data)
export const courseCatalogueDeteleApi = (data) => post(API.courseCatalogueDetele, data)
export const courseCatalogueAddApi = (data) => post(API.courseCatalogueAdd, data)
export const getOperationRecordApi = (data) => post(API.getOperationRecord, data)
