<template>
  <div>
    <div class="trainee-box">
      <div class="trainee-box-title">
        <com-title>课程资料</com-title>
        <div>
          <el-button class="add-group-btn" :style="{'--lock-btn': $store.state.settings.theme}"  @click="choosedDataBank"
            >从资料库中选择</el-button
          >
          <el-button type="primary" @click="uploadCourseFile"
            >上传资料</el-button
          >
        </div>
      </div>
      <div class="content">
          <div class="empty-box" v-if="courseCatalogueList.length === 0">
            <PlaceholderMap text="暂无课程资料噢~" />
          </div>
        <div class="item-content" v-for="(item,index) in courseCatalogueList" :key="index">
          <div class="item-left">
            <img src="@/assets/img/text.png" alt="" />
            <div class="hover-name">{{item.fileName}}</div>
          </div>
          <div class="item-right"><el-button type="text" @click="_deleteTrainee(item,index)">删除</el-button></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import comTitle from '@/components/comTitle/index.vue'
import { courseCatalogueDeteleApi } from '@/api/course'
import PlaceholderMap from '@/components/placeholderMap/index.vue'
export default {
  props: {
    courseCatalogueList: {
      default: () => [],
      type: Array
    }
  },
  components: {
    comTitle,
    PlaceholderMap
  },
  data () {
    return {}
  },
  methods: {
    choosedDataBank () {
      this.$emit('choosedDataBank', '2')
    },
    uploadCourseFile () {
      this.$emit('uploadCourseFile', '2')
    },
    _deleteTrainee (item, index) {
      // 删除
      this.$confirm('确认删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        courseCatalogueDeteleApi([item.id]).then(res => {
          if (res.code === 200) {
            this.$emit('uploadCourseCatalogue')
          }
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.trainee-box-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 21px;
}
.trainee-form {
  margin-bottom: 32px;
  /deep/.el-form-item {
    margin-bottom: 0;
    margin-right: 32px;
  }
  /deep/.el-form-item__label {
    padding-right: 16px;
    color: rgba(0, 0, 0, 0.9);
  }
}
.trainee-table {
  /deep/th {
    height: 54px;
    background-color: #f6f7f9;
    color: rgba(0, 0, 0, 0.9);
    font-size: 14px;
  }
  /deep/td {
    height: 54px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
  }
}
.content {
  background: url("../../../assets/img/data-bg.png");
  padding: 12px 24px;
  padding-bottom: 40px;
  height:  calc(100vh - 440px);
  overflow: auto;
  .item-content {
    display: flex;
    align-content: center;
    justify-content: space-between;
    border-bottom: 1px solid #f3f3f3;
    padding: 12px 0;

    &:last-of-type {
      border: none;
    }
    .item-left {
      display: flex;
      align-items: center;
      img {
        width: 38px;
        height: 38px;
        margin-right: 16px;
      }
    }
  }
}
.add-group-btn {
  width: 130px;
  // border: 1px solid #0089ff;
  font-size: 14px;
  border-color:var(--lock-btn)!important;
  color:var(--lock-btn)!important;
}
// .trainee-box{
  // height:  calc(100vh - 440px);
  // overflow: auto;
// }
</style>
